import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectToExternalUrl({ url }) {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = url;
    }, [navigate, url]);

    return null;
}
