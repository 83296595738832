import React from "react";
import Header from "./Header";
import Footer from "./Footer/index.js";
import { Outlet } from "react-router-dom";

export default function Layout() {
    return (
        <>
            <div className="page">
                <Header />
                <main>
                    <Outlet />
                </main>
                <Footer />
            </div>
            <a id="totop" href="#top">
                <i className="icon-angle-up"></i>
            </a>
        </>
    );
}
