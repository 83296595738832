export const menuData = [
    { to: "/", label: "Home", translationKey: "nav.home", sub: false },
    { to: "/about", label: "About", translationKey: "nav.about", sub: false },
    {
        label: "Services",
        to: "/services",
        translationKey: "nav.service",
        sub: true,
        subMenu: [
            { to: "/services/automation", label: "Automation", translationKey: "service.automation.title" },
            { to: "/services/data-analytics", label: "Data Analytics", translationKey: "service.data.title" },
            { to: "/services/security", label: "Security", translationKey: "service.security.title" }
        ]
    },
    {
        label: "Solutions",
        translationKey: "nav.solution",
        to: "/solutions",
        sub: true,
        subMenu: [
            { to: "/solutions/decisions", label: "Decisions" },
            { to: "/solutions/freshwork", label: "Freshwork" },
            { to: "/solutions/eaton", label: "Eaton" }
        ]
    },
    { to: "/blogs", label: "Blog", translationKey: "nav.blog", sub: false },
    { to: "/contact", label: "Contact", translationKey: "nav.contact", sub: false }
];
