import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = () => {
    const { t } = useTranslation("common");
    return (
        <div>
            <Helmet>
                <title>Không tìm thấy trang</title>
            </Helmet>
            <section className="error-404">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-6 col-sm-6 col-9 m-auto">
                            <div className="cmt-404-img">
                                <img width={570} height={445} className="img-fluid" src="/assets/images/404.png" alt="404.png" />
                            </div>
                            <div className="page-content">
                                <p>{t("404.title")}</p>
                            </div>
                            <Link className="cmt-btn cmt-btn-size-md cmt-btn-shape-rounded cmt-btn-style-border cmt-btn-color-dark" to="/">
                                {t("404.back")}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NotFound;
