import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
    const { t } = useTranslation(["home", "common"]);

    return (
        <footer className="footer widget-footer bg-base-grey clearfix">
            <div className="second-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                            <div className="widget widget_text clearfix">
                                <div className="footer-logo">
                                    <img id="footer-logo-img" className="img-fluid auto_size" height={52} width={312} src="/assets/images/logo.png" alt="img" />
                                </div>
                                <ul className="widget_contact_wrapper" style={{ fontSize: 14 }}>
                                    <li>
                                        <i className="flaticon-phone-call-1" />
                                        <Link to="tel:0904100089">Hotline: 0904 100 089</Link>
                                    </li>
                                    <li>
                                        <i className="flaticon-envelope" />
                                        <Link to="mailto:info@truebpm.vn">info@truebpm.vn</Link>
                                    </li>
                                    <li>
                                        <i className="flaticon-pin" />
                                        Hà Nội / Đà Nẵng / TP Hồ Chí Minh
                                    </li>
                                </ul>
                                {/* <div className="widget_social_wrapper social-icons">
                                    <ul className="list-inline">
                                        <li>
                                            <Link to="https://www.facebook.com/truebpm" target="_blank" rel="noopener" aria-label="facebook">
                                                <i className="icon-facebook" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://twitter.com/truebpm" target="_blank" rel="noopener" aria-label="twitter">
                                                <i className="icon-twitter" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.behance.net/truebpm" target="_blank" rel="noopener" aria-label="linkedin">
                                                <i className="icon-linkedin" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://in.pinterest.com/truebpm/" target="_blank" rel="noopener" aria-label="pinterest">
                                                <i className="icon-pinterest" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://dribbble.com/truebpm" target="_blank" rel="noopener" aria-label="dribbble">
                                                <i className="icon-dribbble" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                                {/* <div className=" widget_cta clearfix">
                                    <h4>
                                        Hotline: <Link to="tel:0904100089">0904100089</Link>
                                    </h4>

                                    <div className="g-map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3833.742858827488!2d108.22069937475813!3d16.078828084602122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1svi!2s!4v1701014931552!5m2!1svi!2s"
                                        title="TrueBPM Viet Name"
                                        aria-label="TrueBPM Viet Name"
                                    ></iframe>
                                    <iframe
                                        src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&t=m&z=10&output=embed&iwloc=near"
                                        title="TrueBPM Viet Name"
                                        aria-label="TrueBPM Viet Name"
                                    />
                                </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
                            <div className="widget multi_widget clearfix">
                                <div className="widget_nav_menu clearfix">
                                    <h3 className="widget-title">{t("footer.link")}</h3>
                                    <ul className="menu-footer-quick-links links-1">
                                        <li>
                                            <Link to="/about">{t("nav.about")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/services">{t("nav.service")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">{t("nav.contact")}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget_nav_menu clearfix">
                                    <h3 className="widget-title">{t("footer.service")}</h3>
                                    <ul className="menu-footer-quick-links links-2">
                                        <li>
                                            <Link to="/services/automation">{t("service.automation.title")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/services/data-analysis">{t("service.data.title")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/services/security">{t("service.security.title")}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                            <div className="widget multi_widget clearfix">
                                <div className="newsletter_widget clearfix">
                                    <h3 className="widget-title">{t("footer.newsletter.title")}</h3>
                                    <p>{t("footer.newsletter.description")}</p>
                                    <form
                                        id="subscribe-form"
                                        className="newsletter-form"
                                        action="https://truebpmvn.myfreshworks.com/crm/sales/smart_form/create_entity?file_attachments_present=false"
                                    >
                                        <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                                            <p>
                                                <input type="email" name="contact[email]" placeholder={t("footer.placeholder.email")} required />
                                            </p>
                                            <p>
                                                <input type="text" name="contact[last_name]" placeholder={t("footer.placeholder.name")} required />
                                            </p>
                                            <p className="d-flex justify-content-end">
                                                <button
                                                    id="subscribe-submit"
                                                    className="cmt-btn cmt-btn-size-md cmt-btn-shape-rounded cmt-btn-style-border cmt-btn-color-skincolor"
                                                    type="submit"
                                                >
                                                    {t("footer.newsletter.button")}
                                                    <i className="icon-right ml-10" />
                                                </button>
                                            </p>
                                        </div>
                                        <div id="subscribe-msg" className="cmt-list fs-14" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer-text copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className="cpy-text">{t("copyright")} © 2023 TRUEBPM VIETNAM</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
