import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LanguageSwitcher from "../../../components/LanguageSwitcher/index.js";
import { menuData } from "../../../configs/menu.js";

const Header = () => {
    const { t } = useTranslation(["home", "common"]);
    return (
        <header id="masthead" className="header cmt-header-style-01">
            <div id="site-header-menu" className="site-header-menu">
                <div className="site-header-menu-inner cmt-stickable-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="site-navigation d-flex align-items-center justify-content-between">
                                    <div className="site-branding me-auto">
                                        <NavLink className="home-link" to="/" title="Devfox" rel="home">
                                            <img
                                                id="logo-img"
                                                height={48}
                                                width={147}
                                                className="img-fluid auto_size"
                                                src="/assets/images/logo.png"
                                                alt="logo-img"
                                            />
                                        </NavLink>
                                    </div>
                                    <div className="btn-show-menu-mobile menubar menubar--squeeze">
                                        <span className="menubar-box">
                                            <span className="menubar-inner" />
                                        </span>
                                    </div>
                                    <nav className="main-menu menu-mobile" id="menu">
                                        <ul className="menu">
                                            {menuData.map((menuItem, index) => (
                                                <li key={index} className="mega-menu-item">
                                                    {!menuItem.sub ? (
                                                        <NavLink to={menuItem.to} className="mega-menu-link" activeClassName="active">
                                                            {t(menuItem.translationKey)}
                                                        </NavLink>
                                                    ) : (
                                                        <NavLink to={menuItem.to} className="mega-menu-link" activeClassName="active">
                                                            {t(menuItem.translationKey)}
                                                        </NavLink>
                                                    )}

                                                    {menuItem.subMenu && (
                                                        <ul className="mega-submenu">
                                                            {menuItem.subMenu.map((subMenuItem, subIndex) => (
                                                                <li key={subIndex}>
                                                                    <NavLink to={subMenuItem.to}>
                                                                        {subMenuItem.translationKey ? t(subMenuItem.translationKey) : subMenuItem.label}
                                                                    </NavLink>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                    <LanguageSwitcher />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
