import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

const langs = [
    {
        value: "vi",
        name: "Tiếng Việt",
        icon: ""
    },
    {
        value: "en",
        name: "English",
        icon: ""
    }
];

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
    };

    return (
        <div className="lang-container">
            <img className="global" width={24} height={24} src="/assets/images/icons/global.svg" alt="" />
            <div className="lang-content">
                {langs.map((item, index) => (
                    <div key={index} className="lang-item" onClick={() => handleLanguageChange(item.value)}>
                        {item.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LanguageSwitcher;
