import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./layouts/MainLayout/index";
import NotFound from "./pages/404";
import RedirectToExternalUrl from "./services/redirect.js";

const Home = lazy(() => import("./pages/home"));
const About = lazy(() => import("./pages/about"));
const Automation = lazy(() => import("./pages/services/automation"));
const Contact = lazy(() => import("./pages/contact"));
const DataAnalytics = lazy(() => import("./pages/services/dataAnalytics"));
const Freshwork = lazy(() => import("./pages/solutions/freshwork"));
const Decisions = lazy(() => import("./pages/solutions/decisions"));
const Security = lazy(() => import("./pages/services/security"));
const Blog = lazy(() => import("./pages/blog"));
const BlogDetail = lazy(() => import("./pages/blog-detail"));
const Project = lazy(() => import("./pages/project"));
const Thankyou = lazy(() => import("./pages/thankforcontact.js"));

const MainRoutes = () => {
    return (
        <Suspense fallback={<></>}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Navigate to="/services/automation" />} />
                    <Route path="/services/automation" element={<Automation />} />
                    <Route path="/services/data-analytics" element={<DataAnalytics />} />
                    <Route path="/services/security" element={<Security />} />
                    <Route path="/solutions" element={<Navigate to="/solutions/decisions" />} />
                    <Route path="/solutions/decisions" element={<Decisions />} />
                    <Route path="/solutions/freshwork" element={<Freshwork />} />
                    <Route path="/blogs" element={<Blog />} />
                    <Route path="/blogs/:id" element={<BlogDetail />} />
                    <Route path="/projects/:id" element={<Project />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/thankforcontact" element={<Thankyou />} />
                    <Route path="/e20" element={<RedirectToExternalUrl url="https://truebpm.vn/quan-ly-nang-luong--tu-dong-hoa--an-toan-dien-nang--iot" />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default MainRoutes;
